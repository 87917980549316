import * as Sentry from '@sentry/nuxt'

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: 'https://14f938cc890dba32fa40fb57a675ba30@o4506253196460032.ingest.us.sentry.io/4506305306624000',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  trackComponents: true,
  timeout: 2000,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
})


Sentry.addEventProcessor(function (event, hint) {
  if (event.exception) {
    // eslint-disable-next-line no-console
    console.error(
      `[Exeption handled by Sentry]: (${hint.originalException})`,
      { event, hint }
    )
  }
  // Continue sending to Sentry
  return event
});
